<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <b-overlay :show="toolbarLoading" variant="white" rounded="sm">
            <template #overlay>
              <div class="text-center">
                Generando PDF...
                <div class="d-flex align-items-center">
                  <b-progress
                    v-model="pdfGenerationPage"
                    class="toolbar-progress-bar"
                    animated
                    :max="pdfGenerationTotalPages"
                  />
                  <FeatherIcon
                    icon="XCircleIcon"
                    size="25"
                    class="text-danger cursor-pointer"
                    @click="pdfGenerationCancelation = true"
                  />
                </div>
              </div>
            </template>
            <TableToolBar
              v-show="!hideToolbar"
              :filters="filters"
              :excluded-filters="excludedFilters"
              :totals="computedTotals"
              :hide-totals-button="hideTotals"
              :hide-import-button="hideImportButton || hideActions"
              :hide-filter-button="hideFilterButton || hideActions"
              :hide-printer-button="hidePrinterButton || hideActions"
              :hide-upload-button="hideUploadButton || hideActions"
              :hide-search-button="hideSearchButton || hideActions"
              :textImportButton="textImportButton"
              :resourceStore="resourceStore"
              @printer-button-click="printList"
              @upload-button-click="exportList"
              @import-button-click="$emit('import-button-click')"
              @filter-button-click="$emit('filter-button-click')"
              @on-clear-active-filters="onClearActiveFilters"
              @totals-button-click="totalRowVisible = $event"
              @search="handleSearch"
            />
          </b-overlay>
          <b-overlay
            :show="localLoading || loading"
            variant="white"
            spinner-variant="primary"
            rounded="sm"
          >
            <b-table
              hover
              responsive
              :items="items"
              :fields="columns"
              :no-local-sorting="true"
              @sort-changed="
                handleSortingChange({
                  column: $event.sortBy,
                  type: $event.sortDesc ? 'desc' : 'asc',
                })
              "
              @row-clicked="$emit('row-clicked', $event)"
            >
              <template #thead-top>
                <b-collapse v-model="totalRowVisible" tag="b-tr">
                  <template v-if="totalRowVisible">
                    <b-th
                      v-for="total in computedTotals"
                      :key="total.label"
                      :colspan="total.colspan || 1"
                      :class="
                        'align-bottom font-weight-bold bg-action-blue ' +
                        (total.align ? `text-${total.align}` : '')
                      "
                    >
                      <div
                        v-show="total.label"
                        class="text-dark-gray font-small"
                      >
                        {{ total.label }}
                      </div>
                      <div
                        v-show="total.value || total.value === 0"
                        class="text-primary text-nowrap"
                      >
                        {{
                          getTotalValue(total.value) + " " + (total.unit || "")
                        }}
                      </div>
                    </b-th>
                  </template>
                </b-collapse>
              </template>
              <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
                <slot :name="slot" v-bind="scope" />
              </template>
              <template #custom-foot="data">
                <slot name="footer" :data="{ ...data, totals }" />
              </template>
            </b-table>
          </b-overlay>

          <div
            v-if="!disablePagination"
            class="d-flex flex-wrap align-items-center justify-content-between mb-1 px-2"
          >
            <div
              class="d-flex align-items-center text-light py-2"
              style="flex-grow: 0"
            >
              <span
                v-if="$store.getters['app/currentBreakPoint'] !== 'xs'"
                class="text-nowrap"
              >
                Mostrando:
              </span>
              <b-form-select
                v-model="pageLength"
                :options="pages"
                class="ml-50 mr-1"
                @input="handlePageLengthChange"
              />
              <span class="text-nowrap"> de: {{ totalItems }} entradas</span>
            </div>
            <div
              :class="
                $store.getters['app/currentBreakPoint'] === 'sm' ||
                $store.getters['app/currentBreakPoint'] === 'xs'
                  ? 'justify-content-center'
                  : 'justify-content-end'
              "
              class="footer-item d-flex"
              style="flex-grow: 1"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="handlePageChange"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from "vue";
import TableToolBar from "@/components/ui/table/TableToolBar.vue";
import DownloadService from "@/shared/services/download-service";

export default {
  name: "BaseTable",
  components: { TableToolBar },
  props: {
    resource: {
      type: String,
      default: null,
    },
    extraResourceList: {
      type: String,
      default: null,
    },
    extraResourceExport: {
      type: String,
      default: null,
    },
    resourceStore: {
      type: String,
      default: null,
    },
    totalsConfiguration: {
      type: Array,
      default: () => [],
    },
    filters: {
      type: Object,
      default: () => {},
    },
    excludedFilters: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hideTotals: {
      type: Boolean,
      default: false,
    },
    hideToolbar: {
      type: Boolean,
      default: false,
    },
    hideFilterButton: {
      type: Boolean,
      default: false,
    },
    hidePrinterButton: {
      type: Boolean,
      default: false,
    },
    hideUploadButton: {
      type: Boolean,
      default: false,
    },
    hideSearchButton: {
      type: Boolean,
      default: false,
    },
    hideImportButton: {
      type: Boolean,
      default: true,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    textImportButton: {
      type: String,
      default: null,
    },
    disableInitialLoad: {
      type: Boolean,
      default: false,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
    initialOrderBy: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      items: [],
      totalItems: 0,
      orderBy: null,
      currentPage: 1,
      pageLength: 10,
      pages: ["5", "10", "25", "50"],
      pdfGenerationPage: 1,
      pdfGenerationTotalPages: Infinity,
      pdfGenerationCancelation: false,
      localLoading: false,
      totals: [],
      toolbarLoading: false,
      totalRowVisible: false,
    };
  },
  computed: {
    search() {
      return this.$store.getters[`${this.resourceStore}/getSearch`];
    },
    computedTotals() {
      if (!this.totals || !this.totalsConfiguration) {
        return [];
      }

      return this.totalsConfiguration.reduce((accomulator, item) => {
        accomulator.push({
          label: item.label,
          value: this.totals[item.key],
          colspan: item.colspan,
          unit: item.unit,
          align: item.align || "right",
        });
        return accomulator;
      }, []);
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.currentPage = 1;
        this.loadData();
      },
    },
  },
  mounted() {
    this.orderBy = this.initialOrderBy;
    if (!this.disableInitialLoad) {
      this.loadData();
    }
  },
  beforeDestroy() {
    this.pdfGenerationCancelation = true;
  },
  methods: {
    async loadData() {
      if (!this.resource) {
        return;
      }

      try {
        this.localLoading = true;
        console.log(this.filters);
        const filters = {
          search: this.search,
          page: this.disablePagination ? null : this.currentPage,
          per_page: this.disablePagination ? null : this.pageLength,
          orderBy: this.orderBy,
          ...this.filters,
        };

        // if(Array.isArray(filters.status)) {
        //   filters.status = filters.status.map(({ id }) => id)
        // }

        // Recorre cada clave en filters y si es un array de objetos, extrae solo los IDs
        Object.keys(filters).forEach((key) => {
          if (Array.isArray(filters[key])) {
            filters[key] = filters[key].map((item) =>
              item?.id !== undefined ? item.id : item
            );
          }
        });

        const response = await Vue.prototype.$http.post(
          `/${this.resource}/${this.extraResourceList || "list"}`,
          filters
        );
        this.items = response.data.data;
        this.totalItems = response.data.meta?.total[1];
        this.totals = response.data.totals;
        this.$emit("data-loaded", {
          items: this.items,
          totals: this.totals,
          totalItems: this.totalItems,
        });
      } catch (error) {
        console.error(error);
        this.$toast.error(
          "Ocurrió un error al obtener los datos. Por favor inténtelo de nuevo mas tarde."
        );
      }

      this.localLoading = false;
    },
    handleSortingChange(orderBy) {
      this.orderBy = orderBy;
      this.loadData();
    },
    handleSearch() {
      this.currentPage = 1;
      this.loadData();
    },
    handlePageLengthChange() {
      this.currentPage = 1;
      this.loadData();
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.loadData();
    },
    async exportList() {
      this.localLoading = true;
      try {

        const filters = {
          search: this.search,
          page: this.disablePagination ? null : this.currentPage,
          per_page: this.disablePagination ? null : this.pageLength,
          orderBy: this.orderBy,
          ...this.filters,
        };

        Object.keys(filters).forEach((key) => {
          if (Array.isArray(filters[key])) {
            filters[key] = filters[key].map((item) =>
              item?.id !== undefined ? item.id : item
            );
          }
        });

        const response = await Vue.prototype.$http.post(
          `/${this.resource}/${this.extraResourceExport || "export"}`,
          filters,
          { responseType: "blob" }
        );
        const filename = DownloadService.getFilenameFromResponse(response);
        DownloadService.resolveAndDownloadBlob(
          response?.data || null,
          filename
        );
      } catch (error) {
        console.error(error);
        this.$toast.error(
          "Error en la descarga del listado. Por favor inténtelo de nuevo mas tarde."
        );
      }
      this.localLoading = false;
    },
    async printList() {
      this.toolbarLoading = true;
      let response = null;
      try {
        while (
          this.pdfGenerationPage <= this.pdfGenerationTotalPages &&
          !this.pdfGenerationCancelation
        ) {

        const filters = {
          search: this.search,
          page: this.pdfGenerationPage,
          orderBy: this.orderBy,
          ...this.filters,
        };

        Object.keys(filters).forEach((key) => {
          if (Array.isArray(filters[key])) {
            filters[key] = filters[key].map((item) =>
              item?.id !== undefined ? item.id : item
            );
          }
        });

          // eslint-disable-next-line no-await-in-loop
          response = await Vue.prototype.$http.post(
            `/${this.resource}/print`,
           filters,
            {
              responseType:
                this.pdfGenerationPage === this.pdfGenerationTotalPages
                  ? "blob"
                  : null,
            }
          );
          this.pdfGenerationPage += 1;
          this.pdfGenerationTotalPages = response?.data?.total + 1 || 0;
        }

        if (response?.data && !this.pdfGenerationCancelation) {
          const filename = DownloadService.getFilenameFromResponse(response);
          DownloadService.resolveAndDownloadBlob(response.data, filename);
        }
      } catch (error) {
        console.error(error);
        this.$toast.error(
          "Error en la descarga del listado. Por favor inténtelo de nuevo mas tarde."
        );
      }
      this.pdfGenerationPage = 1;
      this.pdfGenerationTotalPages = Infinity;
      this.pdfGenerationCancelation = false;
      this.toolbarLoading = false;
    },
    onClearActiveFilters() {
      // this.searchTerm = null
      this.$store.commit(`${this.resourceStore}/setSearch`, null);
      this.$emit("on-clear-active-filters");
    },
    getTotalValue(total) {
      if (typeof total === "number") {
        return this.$options.filters.numberToLocalString(total);
      }

      return total;
    },
  },
};
</script>

<style scoped>
.footer-item {
  flex-grow: 1;
}
.toolbar-progress-bar {
  min-width: 300px;
  height: 20px;
}
</style>
